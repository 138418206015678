<template>
  <mi-tr class="validation-result-item" :selected="expanded">
    <mi-td v-for="col in cols" :key="col.name">
      <!-- Validation status -->
      <div v-if="col.name === 'status'" class="flex items-center no-wrap">
        <!-- Badge -->
        <mi-badge :color="col.value.color" class="text-uppercase q-mr-sm">
          {{ col.value.title }}
        </mi-badge>

        <div
          v-if="col.value.title === RESULT_STATUS.FAILED.title"
          class="q-ml-auto"
        >
          <!-- Close explanation button -->
          <mi-btn
            v-if="expanded"
            class="validation-result-item__btn text-overline"
            icon="close"
            icon-size=".875rem"
            fab
            icon-type
            flat
            @click="$emit('click:close')"
          ></mi-btn>

          <!-- Why explanation button -->
          <mi-btn
            v-else
            class="validation-result-item__btn"
            color="accent"
            dense
            @click="$emit('click:why')"
          >
            Why
          </mi-btn>
        </div>
      </div>

      <template v-else> {{ col.value || '-' }} </template>
    </mi-td>
  </mi-tr>
</template>

<script>
  import { RESULT_STATUS } from '@/constants'

  export default {
    name: 'TestCaseValidationResultsItem',
    props: {
      cols: {
        type: Array,
        required: true
      },
      expanded: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['click:close', 'click:why'],
    data: () => ({ RESULT_STATUS })
  }
</script>

<style lang="scss" scoped>
  .validation-result-item__btn.mi-btn {
    min-height: 1.75rem;

    &.q-btn--fab {
      min-width: 1.75rem;
      padding: .25rem;
    }
  }
</style>
