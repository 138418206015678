<template>
  <mi-table
    ref="miTable"
    :columns="columns"
    :loading="areValidationResultsLoading"
    :rows="testCaseValidationResults"
    class="test-case-validation-results-table full-height q-px-lg q-pb-md"
    column-sort-order="da"
    loading-label="Loading validation results. Please wait ..."
    no-data-label="No test case validation results found"
    row-key="id"
    title="Validation results"
    virtual-scroll-sticky-size-start="40"
    hide-pagination
    sticky-header
    striped
    wrap-cells
  >
    <template #body="{ cols, key, row, rowIndex }">
      <!-- Validation result item -->
      <validation-result-item
        :key="key"
        :cols="cols"
        :expanded="expandedValidationResultId === row.id"
        @click:close="expandedValidationResultId = false"
        @click:why="getValidationResultExplanation({ resultId: row.id, resultIndex: rowIndex })"
      ></validation-result-item>
      <!-- Validation explanation row -->
      <mi-tr v-if="expandedValidationResultId === row.id" no-hover>
        <mi-td colspan="100%">
          <validation-explanation :explanation="validationResultExplanation" class="q-py-sm"></validation-explanation>
        </mi-td>
      </mi-tr>
    </template>
  </mi-table>
</template>

<script>
  import {
    getTestCaseValidation,
    getTestCaseValidationExplanation,
    requestTestCaseValidationExplanation
  } from '@/api'
  import { removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import {
    MiTable,
    MiTr,
    MiTd
  } from '@/packages/@mi-library'

  import { RESULT_STATUS } from '@/constants'
  import { TEST_CASES_WHY } from '@/utils/analytics/constants'

  import ValidationExplanation from '@/components/validations/ValidationExplanation.vue'
  import { poll } from '@/api/polling'
  import notify from '@/utils/notify'
  import ValidationResultItem from './TestCaseValidationResultsItem.vue'

  export default {
    name: 'TestCaseValidationResults',
    components: {
      ValidationExplanation,
      ValidationResultItem,
      MiTable,
      MiTr,
      MiTd
    },
    data: () => ({
      areValidationResultsLoading: false,
      testCaseValidationResults: [],
      expandedValidationResultId: '',
      validationResultExplanation: {
        conflicts: [],
        relevantChoices: []
      },
      columns: [
        {
          name: 'id',
          label: 'Row number',
          align: 'left',
          field: 'id',
          headerStyle: 'min-width: 140px',
          sortable: true
        },
        {
          name: 'logicalExpression',
          label: 'Logical expression',
          align: 'left',
          field: 'logicalExpression',
          sortable: true
        },
        {
          name: 'planningPeriodRange',
          align: 'left',
          label: 'Failed (From - To)',
          field: ({ planningPeriodRange: { from, to } = {} } = {}) => `
            ${ removePrefixFromPlanningPeriod(from) } - ${ removePrefixFromPlanningPeriod(to) }
          `,
          headerStyle: 'min-width: 200px',
          sortable: true
        },
        {
          name: 'status',
          label: 'Status',
          align: 'left',
          field: 'testCaseValidationStatus',
          headerStyle: 'width: 180px',
          format: value => RESULT_STATUS[value] || {},
          sortable: true
        }
      ],
      clearPoll: null
    }),
    async created() {
      await this.fetchTestCaseValidationResults()
    },
    beforeUnmount() {
      this.clearPoll?.()
    },
    methods: {
      async fetchTestCaseValidationResults() {
        const { testCaseValidationId } = this.$route.params || ''

        this.areValidationResultsLoading = true

        try {
          ({ validationResults: this.testCaseValidationResults } = await getTestCaseValidation(testCaseValidationId))
        }
        finally {
          this.areValidationResultsLoading = false
        }
      },
      async getValidationResultExplanation({ resultId = '', resultIndex = -1 } = {}) {
        try {
          this.areValidationResultsLoading = true

          const { testCaseValidationId: validationId } = this.$route.params

          const { executePoll, clearPoll } = poll()
          this.clearPoll = clearPoll

          const { whyExplanationResponse } = await executePoll({
            correlationIdEndpoint: {
              getCorrelationId: requestTestCaseValidationExplanation,
              params: { validationId, validationResultId: resultId }
            },
            asyncEndpoint: getTestCaseValidationExplanation
          })

          this.validationResultExplanation = whyExplanationResponse
          this.expandedValidationResultId = resultId

          if (resultIndex > -1) {
            setTimeout(() => {
              this.$refs.miTable?.scrollTo(resultIndex, 'center')
            }, 100)
          }

          recordAnalytics(TEST_CASES_WHY)
        }
        catch (e) {
          notify({
            title: `Error ${ e.status || '' }`,
            content: 'Fetch test case validation explanation',
            type: 'negative'
          })
        }
        finally {
          this.areValidationResultsLoading = false
        }
      }
    }
  }
</script>
